import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, Box, Card, CardContent, CardHeader,
  Tabs, Tab, TextField, Select, MenuItem, FormControlLabel,
  Switch, Button, Grid, InputLabel, FormControl, CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { LiveKitRoom, AudioConference, RoomAudioRenderer } from '@livekit/components-react';
import { Room, RoomEvent, ParticipantEvent } from 'livekit-client';
import { useLocation } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languages = [
  { value: "en-US", label: "English (US)" },
  { value: "en-IN", label: "English (India)" },
  { value: "en-GB", label: "English (UK)" },
  { value: "de-DE", label: "German" },
  { value: "es-ES", label: "Spanish (Spain)" },
  { value: "es-419", label: "Spanish (Latin America)" },
  { value: "hi-IN", label: "Hindi" },
  { value: "ja-JP", label: "Japanese" },
  { value: "pt-PT", label: "Portugese (Portugal)" },
  { value: "pt-BR", label: "Portugese (Brazil)" },
  { value: "fr-FR", label: "French" },
  { value: "zh-CN", label: "Chinese" },
  { value: "ru-RU", label: "Russian" },
  { value: "it-IT", label: "Italian" },
  { value: "ko-KR", label: "Korean" },
  { value: "nl-NL", label: "Dutch" },
  { value: "pl-PL", label: "Polish" },
  { value: "tr-TR", label: "Turkish" },
  { value: "vi-VN", label: "Vietnamese" },
  { value: "multi", label: "Multilingual (English & Spanish)" }
];

const businessTypes = [
  { value: "hotel", label: "Hotel" },
  { value: "resort", label: "Resort" },
  { value: "motel", label: "Motel" },
  { value: "bnb", label: "Bed & Breakfast" },
  { value: "hostel", label: "Hostel" },
  { value: "vacation_rental", label: "Vacation Rental" },
  { value: "other_hospitality", label: "Other Hospitality" }
];

const templateData = {
  hotel: {
    description: "We are a premier hotel dedicated to providing exceptional guest experiences. Our professional staff ensures personalized service, luxurious accommodations, and memorable stays for all our guests.",
    greeting: "Thank you for calling [Business Name]. How may we assist you with your stay today?",
    services: ["Room Reservations", "Concierge Services", "Room Service", "Event Planning", "Airport Shuttle", "Restaurant & Dining", "Business Center"],
    faq: [
      "Q: What is your check-in/check-out time?\nA: Check-in time is 3:00 PM and check-out time is 11:00 AM. Early check-in and late check-out may be available upon request.",
      "Q: Do you offer airport transportation?\nA: Yes, we provide shuttle service to and from the airport. Please contact us at least 24 hours in advance to arrange transportation.",
      "Q: What amenities are included with the room?\nA: Our rooms include complimentary Wi-Fi, flat-screen TV, mini-fridge, coffee maker, and daily housekeeping service. Additional amenities vary by room type."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  resort: {
    description: "Experience luxury and relaxation at our world-class resort. We offer stunning accommodations, exceptional amenities, and a wide range of recreational activities to ensure an unforgettable vacation experience.",
    greeting: "Welcome to [Business Name]. How may we help make your resort experience extraordinary today?",
    services: ["Luxury Accommodations", "Spa & Wellness", "Multiple Restaurants", "Recreation Activities", "Pool & Beach Access", "Event Venues", "Kids Club"],
    faq: [
      "Q: What recreational activities do you offer?\nA: We offer a variety of activities including swimming, spa services, fitness classes, water sports, and organized excursions.",
      "Q: Are dining reservations required?\nA: We recommend reservations for our specialty restaurants, especially during peak season. Our concierge can assist you with all dining arrangements.",
      "Q: Do you have special packages or seasonal offers?\nA: Yes, we offer various seasonal packages and special promotions. Please ask about our current offers."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  motel: {
    description: "We provide comfortable and affordable accommodations for travelers. Our convenient location and friendly service make us the perfect choice for both short stays and extended visits.",
    greeting: "Thank you for calling [Business Name]. How may we help you with your stay today?",
    services: ["Clean Rooms", "Free Parking", "Wi-Fi Access", "24/7 Front Desk", "Vending Machines", "Local Information"],
    faq: [
      "Q: Do you accept walk-ins?\nA: Yes, we accept walk-in guests subject to availability. However, we recommend making a reservation to ensure a room is available.",
      "Q: What payment methods do you accept?\nA: We accept all major credit cards and cash. Payment is required at check-in.",
      "Q: Is parking included?\nA: Yes, we offer free parking for all our guests."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  bnb: {
    description: "Welcome to our charming bed & breakfast, where we combine comfortable accommodations with personalized hospitality. Experience a home away from home with our unique rooms and homemade breakfast.",
    greeting: "Hello, you've reached [Business Name]. How may we make your stay special?",
    services: ["Unique Room Accommodations", "Homemade Breakfast", "Local Tourism Info", "Wi-Fi Access", "Garden Access", "Parking"],
    faq: [
      "Q: Is breakfast included in the rate?\nA: Yes, we serve a complimentary homemade breakfast each morning from 7:00 AM to 9:30 AM.",
      "Q: Do you accommodate dietary restrictions?\nA: Yes, please let us know about any dietary requirements when booking, and we'll be happy to accommodate them.",
      "Q: Can you recommend local attractions?\nA: Absolutely! We have extensive knowledge of local attractions and can provide personalized recommendations."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  hostel: {
    description: "Our hostel offers budget-friendly accommodations in a social atmosphere. Perfect for backpackers and travelers looking to meet people from around the world while enjoying comfortable shared spaces.",
    greeting: "Welcome to [Business Name]. How can we help you with your stay today?",
    services: ["Dormitory Beds", "Private Rooms", "Shared Kitchen", "Common Areas", "Laundry Facilities", "Travel Info", "Lockers"],
    faq: [
      "Q: Do you provide bedding?\nA: Yes, all beds come with clean linens. Towels are available for rent or purchase.",
      "Q: Is there a curfew?\nA: No, we don't have a curfew. Guests can come and go 24/7 with their key card.",
      "Q: Do you have secure storage?\nA: Yes, we provide lockers in the dormitories. Please bring your own lock or purchase one at reception."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  },
  vacation_rental: {
    description: "Our vacation rentals offer the perfect home-away-from-home experience. Enjoy spacious accommodations with full amenities and the privacy of your own space in prime locations.",
    greeting: "Thank you for calling [Business Name]. How may we assist you with your vacation rental today?",
    services: ["Vacation Homes", "Apartments", "Cleaning Service", "Property Management", "24/7 Support", "Local Recommendations"],
    faq: [
      "Q: What's included in the rental?\nA: Our rentals include fully equipped kitchens, linens, towels, and basic supplies. Each property has its own unique amenities list.",
      "Q: How do I check in?\nA: We provide detailed check-in instructions, including access codes or key pickup information, prior to your arrival.",
      "Q: Is there a minimum stay requirement?\nA: Minimum stay requirements vary by property and season. Please inquire about specific properties."
    ],
    schedule: {
      Monday: { open: "09:00", close: "17:00" },
      Tuesday: { open: "09:00", close: "17:00" },
      Wednesday: { open: "09:00", close: "17:00" },
      Thursday: { open: "09:00", close: "17:00" },
      Friday: { open: "09:00", close: "17:00" },
      Saturday: { open: "10:00", close: "14:00" },
      Sunday: { open: "Closed", close: "Closed" }
    }
  },
  other_hospitality: {
    description: "We are a unique hospitality establishment dedicated to providing exceptional guest experiences. Our professional staff ensures personalized service and memorable stays for all our guests.",
    greeting: "Thank you for calling [Business Name]. How may we assist you today?",
    services: ["Accommodations", "Guest Services", "Amenities", "Local Information", "Special Requests"],
    faq: [
      "Q: What are your operating hours?\nA: Please inquire about our specific operating hours and availability.",
      "Q: Do you offer special rates or packages?\nA: Yes, we offer various rates and packages. Please ask about our current promotions.",
      "Q: What amenities do you provide?\nA: We offer a range of amenities to enhance your stay. Please ask about our specific offerings."
    ],
    schedule: {
      Monday: { open: "00:00", close: "23:59" },
      Tuesday: { open: "00:00", close: "23:59" },
      Wednesday: { open: "00:00", close: "23:59" },
      Thursday: { open: "00:00", close: "23:59" },
      Friday: { open: "00:00", close: "23:59" },
      Saturday: { open: "00:00", close: "23:59" },
      Sunday: { open: "00:00", close: "23:59" }
    }
  }
};
const AutoResizeTextarea = styled(TextField)({
  '& .MuiInputBase-root': {
    height: 'auto',
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    resize: 'none',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function BusinessDashboardPreview() {
  const [businessType, setBusinessType] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("en-US");
  const [customData, setCustomData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [aiVoice, setAiVoice] = useState("");
  const [availableVoices, setAvailableVoices] = useState([]);
  const [afterHoursMessaging, setAfterHoursMessaging] = useState(false);
  const [appointmentDuration, setAppointmentDuration] = useState("");
  const [bufferTime, setBufferTime] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [liveKitToken, setLiveKitToken] = useState("");  // State for LiveKit token
  const [liveKitServerUrl, setLiveKitServerUrl] = useState("");  // State for LiveKit server URL
  const [connectLiveKit, setConnectLiveKit] = useState(false);  // State to handle user gesture for audio context
  const [mediaStream, setMediaStream] = useState(null); // State to manage media stream
  const [isCallActive, setIsCallActive] = useState(false); // New state to track if a call is active
  const [loading, setLoading] = useState(true); // New state for loading
  const [dataLoaded, setDataLoaded] = useState(false); // New state to track if data has been loaded
  const [browserSupported, setBrowserSupported] = useState(true);
  const [mewsConnected, setMewsConnected] = useState(false);
  const roomRef = useRef(null);  // Reference to the Room instance
  const audioElementsRef = useRef({});  // Reference to store audio elements
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('access_token');
    if (token) {
      localStorage.setItem('accessToken', token);
      // Remove access_token from URL
      params.delete('access_token');
      fetchAgentConfiguration();
      fetchVoices();
      // navigate({
      //   pathname: location.pathname,
      //   search: params.toString(),
      // }, { replace: true });
    } else {
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        window.location.href = "https://aivira.co/";
      }
      fetchAgentConfiguration();
      fetchVoices();
    }
    // Check browser support
    const isSupported = 'RTCPeerConnection' in window;
    setBrowserSupported(isSupported);
  }, []); // Removed navigate from dependency array

  const requestAudioPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (error) {
      console.error('Error requesting audio permission:', error);
      return false;
    }
  };
  const fetchAgentConfiguration = async () => {
      setLoading(true);
      try {
        const accessToken = localStorage.getItem('accessToken');
  
        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }
  
        const response = await fetch('https://api.aivira.co/ai-receptionist', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
  
        const data = await response.json();
  
        if (response.ok) {
          // Populate state with existing configuration
          setBusinessType(data.businessType);
          setBusinessName(data.businessName);
          setMewsConnected(data.mewsConnected);
          
          // Parse JSON strings to JavaScript objects/arrays
          const faqArray = JSON.parse(data.faq); // Convert JSON string to array
          const servicesArray = JSON.parse(data.services); // Convert JSON string to array
          const scheduleObject = JSON.parse(data.schedule); // Convert JSON string to object

          setCustomData({
            description: data.description,
            greeting: data.greeting.replace("[Business Name]", data.businessName),
            faq: faqArray,
            services: servicesArray,
            schedule: scheduleObject,
          });

          setAiVoice(data.aiVoice);
          if (data.preferredLanguage) {
            setPreferredLanguage(data.preferredLanguage);
          }
          setDataLoaded(true);
        } else if (response.status === 404) {
          console.log('No agent found for this user.');
          // Handle case where there's no existing agent
          setDataLoaded(false);
        } else {
          console.error('Failed to fetch agent configuration:', data.error);
          setDataLoaded(false);
        }
      } catch (error) {
        console.error('Error fetching agent configuration:', error);
        setDataLoaded(false);
      } finally {
        setLoading(false);
      }
    };
  
  useEffect(() => {
    fetchAgentConfiguration();
  }, []);

  // Fetch available voices
    const fetchVoices = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch('https://api.aivira.co/list-voices', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
  
        const voices = await response.json();
        setAvailableVoices(voices);
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };
    
  useEffect(() => {
  
    fetchVoices();
  }, []);

  // Fetch live call data on load
  useEffect(() => {
    const fetchLiveCallData = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }

        const response = await fetch('https://api.aivira.co/create-web-call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            businessType,
            businessName,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          const wsUrl = `wss://retell-ai-4ihahnq7.livekit.cloud/rtc?access_token=${result.access_token}&auto_subscribe=1&sdk=js&version=2.5.1&protocol=15`;
          setLiveKitToken(result.access_token);  // Set token state
          setLiveKitServerUrl(wsUrl);  // Set server URL state
          const room = new Room();
          await room.connect(wsUrl, result.access_token);
          console.log('connected to room', room.name);
          roomRef.current = room;  // Store room in ref
    
          // Publish local camera and mic tracks
          await room.localParticipant.setMicrophoneEnabled(true);
    
        } else {
          throw new Error(result.error || 'Failed to fetch live call data');
        }
      } catch (error) {
        console.error('Error fetching live call data:', error);
      }
    };

    if (connectLiveKit) {
      fetchLiveCallData();
    }
  }, [connectLiveKit]);  // Fetch again if connectLiveKit or mediaStream changes

  const handleBusinessTypeChange = (event) => {
    const value = event.target.value;
    setBusinessType(value);
    setCustomData(templateData[value] || {});
    setHasUnsavedChanges(true);
    // Scroll to the General settings section
    const generalSettingsSection = document.getElementById('general-settings-section');
    if (generalSettingsSection) {
      generalSettingsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleScheduleChange = (day, type, value) => {
    setCustomData(prevData => ({
      ...prevData,
      schedule: {
        ...prevData.schedule,
        [day]: {
          ...prevData.schedule[day],
          [type]: value
        }
      }
    }));
    setHasUnsavedChanges(true);
  };

  const handleInputChange = () => {
    setHasUnsavedChanges(true);
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach(textarea => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    });
  }, [customData]);

const handleSave = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('Access token is missing. Please log in again.');
    }

    if (!aiVoice) {
      throw new Error('AI Voice is required. Please select an AI Voice before saving.');
    }
    
    if (!preferredLanguage) {
      throw new Error('Preferred Language is required. Please type Preferred Language before saving.');
    }

    if (!businessName) {
      throw new Error('Business Name is required. Please type Business Name before saving.');
    }

    const response = await fetch('https://api.aivira.co/ai-receptionist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        businessType,
        businessName,
        description: customData.description,
        aiVoice,
        afterHoursMessaging,
        greeting: customData.greeting.replace("[Business Name]", businessName),
        faq: customData.faq,
        services: customData.services,
        schedule: customData.schedule,
        appointmentDuration,
        bufferTime,
        preferredLanguage,
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || 'Failed to save AI Agent configuration');
    }

    console.log('AI agent configuration saved:', result);
    fetchAgentConfiguration();
    setHasUnsavedChanges(false);
    // alert('Configuration saved successfully!');
  } catch (error) {
    console.error('Error saving AI agent configuration:', error);
    alert(error.message);
  }
};

const handleMewsConnection = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      if (!mewsConnected) {
        // Connect to Mews
        const response = await fetch('https://api.aivira.co/connect-mews', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to connect to Mews Demo Hotel');
        }

        setMewsConnected(true);
      } else {
        // Disconnect from Mews
        const response = await fetch('https://api.aivira.co/disconnect-mews', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to disconnect from Mews Demo Hotel');
        }

        setMewsConnected(false);
      }
    } catch (error) {
      console.error('Error handling Mews connection:', error);
      alert(error.message);
    }
  };

const handleConnectLiveKit = async () => {
  if (!isCallActive) {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      setLoading(true);
      const response = await fetch('https://api.aivira.co/create-web-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          businessType,
          businessName,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || 'Failed to create web call');
      }

      const wsUrl = `wss://retell-ai-4ihahnq7.livekit.cloud`;
      setLiveKitToken(result.access_token);
      setLiveKitServerUrl(wsUrl);
      setIsCallActive(true);

    } catch (error) {
      console.error('Error in handleConnectLiveKit:', error);
      alert(error.message || 'Failed to start audio test. Please try again.');
    } finally {
      setLoading(false);
    }
  } else {
    setIsCallActive(false);
    setLiveKitToken('');
    setLiveKitServerUrl('');
  }
};


if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dataLoaded) {
    return (
      <Box sx={{ p: 4, maxWidth: '4xl', mx: 'auto' }}>
        <Card>
          <CardHeader title="Welcome to Aivira AI!" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              It looks like you haven't set up your AI Agent yet. Let's get started!
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => setDataLoaded(true)}
              sx={{ mt: 2 }}
            >
              Set Up Your AI Agent
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '4xl', mx: 'auto' }}>
      <Card sx={{ mb: 4 }}>
        <CardHeader 
          title="Select Your Business Type"
          subheader="We'll customize the AI Agent based on your business needs"
          action={
            <Button 
              variant="contained" 
              color={isCallActive ? "error" : "primary"} 
              onClick={handleConnectLiveKit}
              sx={{ backgroundColor: isCallActive ? "#d32f2f" : undefined }}
            >
              {isCallActive ? "End the call" : "Test Audio"}
            </Button>
          }
        />
        <CardContent>
          <FormControl fullWidth>
            <InputLabel id="business-type-label">Choose your business type</InputLabel>
            <Select
              labelId="business-type-label"
              value={businessType}
              onChange={handleBusinessTypeChange}
              label="Choose your business type"
            >
              {businessTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
         <Button
            variant="contained"
            color={mewsConnected ? "error" : "primary"}
            onClick={handleMewsConnection}
            sx={{
              backgroundColor: mewsConnected ? "#d32f2f" : undefined,
              width: 'auto',
              padding: '8px 20px',
              mt: 2,
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 15px rgba(0,0,0,0.3)',
              }
            }}
            size="medium"
          >
            {mewsConnected
              ? "Disconnect from Mews & The Fork"
              : "Connect to Mews (PMS) & The Fork (Restaurant) Demo Account"}
          </Button>

        </CardContent>
      </Card>

      {/* LiveKit Room */}
      {isCallActive && liveKitToken && liveKitServerUrl && (
        <div className="hidden">
          <LiveKitRoom
            serverUrl={liveKitServerUrl}
            token={liveKitToken}
            connect={true}
            audio={true}
            video={false}
            options={{
              adaptiveStream: true,
              dynacast: true,
              stopMicTrackOnMute: false,
              publishDefaults: {
                simulcast: true,
                audioPreset: {
                  maxBitrate: 96000,
                  maxQuality: true
                }
              }
            }}
          >
            <div className="hidden">
              <AudioConference />
              <RoomAudioRenderer />
            </div>
          </LiveKitRoom>
        </div>
      )}

      {businessType && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="dashboard tabs">
              <Tab label="General" />
              <Tab label="Scripts" />
              <Tab label="Services" />
              <Tab label="Schedule" />
            </Tabs>
          </Box>
          <Box sx={{ height: '100%', overflow: 'auto' }}>
          
          <TabPanel value={tabValue} index={0}>
            <Card id="general-settings-section">
              <CardHeader 
                title="General Settings" 
                subheader="Customize your AI Agent's voice and behavior" 
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                  <TextField 
                    fullWidth 
                    label="Business Name" 
                    placeholder="Enter your business name"
                    value={businessName}
                    onChange={(e) => {
                      setBusinessName(e.target.value);
                      handleInputChange();
                    }}
                  />
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Business Description"
                    placeholder="Provide a brief description of your business"
                    value={customData.description || ""}
                    onChange={(e) => {
                      setCustomData({...customData, description: e.target.value});
                      handleInputChange();
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="preferred-language-label">Preferred Language</InputLabel>
                    <Select
                      labelId="preferred-language-label"
                      label="Preferred Language"
                      value={preferredLanguage}
                      onChange={(e) => {
                        setPreferredLanguage(e.target.value);
                        handleInputChange();
                      }}
                    >
                      {languages.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                          {lang.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="ai-voice-label">AI Voice</InputLabel>
                    <Select
                      labelId="ai-voice-label"
                      label="AI Voice"
                      value={aiVoice}
                      onChange={(e) => {
                        setAiVoice(e.target.value);
                        handleInputChange();
                      }}
                    >
                      {availableVoices && availableVoices.map((voice) => (
                        <MenuItem key={voice.voice_id} value={voice.voice_id}>
                          {voice.voice_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={afterHoursMessaging}
                        onChange={(e) => {
                          setAfterHoursMessaging(e.target.checked);
                          handleInputChange();
                        }}
                      />
                    }
                    label="Enable after-hours messaging"
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            <Card>
              <CardHeader 
                title="Scripts & FAQs" 
                subheader="Customize your greeting and frequently asked questions"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Greeting Message"
                    placeholder="Enter your greeting message"
                    value={customData.greeting || ""}
                    onChange={(e) => {
                      setCustomData({...customData, greeting: e.target.value});
                      handleInputChange();
                    }}
                  />
                  <AutoResizeTextarea
                    fullWidth
                    multiline
                    label="Frequently Asked Questions"
                    placeholder="Enter FAQ in Q&A format"
                    value={customData.faq ? customData.faq.join("\n\n") : ""}
                    onChange={(e) => {
                      setCustomData({...customData, faq: e.target.value.split("\n\n")});
                      handleInputChange();
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={2}>
            <Card>
              <CardHeader 
                title="Services" 
                subheader="Manage the services your business offers"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Services Offered</Typography>
                {customData.services && customData.services.map((service, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <TextField 
                      value={service}
                      onChange={(e) => {
                        const newServices = [...customData.services];
                        newServices[index] = e.target.value;
                        setCustomData({...customData, services: newServices});
                        handleInputChange();
                      }}
                      fullWidth
                      sx={{ mr: 2 }}
                    />
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={() => {
                        const newServices = customData.services.filter((_, i) => i !== index);
                        setCustomData({...customData, services: newServices});
                        handleInputChange();
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
                <Button 
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={() => {
                    const newServices = [...(customData.services || []), "New Service"];
                    setCustomData({...customData, services: newServices});
                    handleInputChange();
                  }}
                >
                  Add Service
                </Button>
              </CardContent>
            </Card>
          </TabPanel>
          
          <TabPanel value={tabValue} index={3}>
            <Card>
              <CardHeader 
                title="Scheduling" 
                subheader="Set up your business hours and appointment settings"
                action={
                  <Button 
                    variant="contained" 
                    color={hasUnsavedChanges ? "primary" : "inherit"}
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Business Hours</Typography>
                <Grid container spacing={2}>
                  {Object.entries(customData.schedule || {}).map(([day, hours]) => (
                    <Grid item xs={12} key={day}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ width: 100 }}>{day}</Typography>
                        <TextField 
                          type="time" 
                          sx={{ width: 140, mr: 2 }} 
                          value={hours.open}
                          onChange={(e) => handleScheduleChange(day, 'open', e.target.value)}
                          disabled={hours.open === "Closed"}
                        />
                        <Typography sx={{ mx: 2 }}>to</Typography>
                        <TextField 
                          type="time" 
                          sx={{ width: 140 }} 
                          value={hours.close}
                          onChange={(e) => handleScheduleChange(day, 'close', e.target.value)}
                          disabled={hours.close === "Closed"}
                        />
                        <FormControlLabel
                          control={
                            <Switch 
                              checked={hours.open !== "Closed"}
                              onChange={(e) => {
                                const newValue = e.target.checked ? "09:00" : "Closed";
                                handleScheduleChange(day, 'open', newValue);
                                handleScheduleChange(day, 'close', e.target.checked ? "17:00" : "Closed");
                              }}
                            />
                          }
                          label="Open"
                          sx={{ ml: 2 }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="appointment-duration-label">Appointment Duration</InputLabel>
                  <Select 
                    labelId="appointment-duration-label" 
                    label="Appointment Duration"
                    value={appointmentDuration}
                    onChange={(e) => {
                      setAppointmentDuration(e.target.value);
                      handleInputChange();
                    }}
                  >
                    <MenuItem value="15">15 minutes</MenuItem>
                    <MenuItem value="30">30 minutes</MenuItem>
                    <MenuItem value="60">1 hour</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={bufferTime}
                      onChange={(e) => {
                        setBufferTime(e.target.checked);
                        handleInputChange();
                      }}
                    />
                  }
                  label="Add buffer time between appointments"
                  sx={{ mt: 2 }}
                />
              </CardContent>
            </Card>
          </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  );
}
